<template>
  <AppCard>
    <AppCardImageTitle
      :src="require('@galileo/assets/images/illustrations/warning.svg')"
      alt="Warning"
      :title="$t('SendMoneyVerifyIdentityFailed.Title').value"
    >
      <p>
        {{ $t('SendMoneyVerifyIdentityFailed.Description').value }}
      </p>
    </AppCardImageTitle>
    <template #footer>
      <AppCardFooter>
        <AppButton
          theme="secondary"
          analytics-name="send-verify-identity-failed-help"
          @click="$router.push('/contact-form')"
        >
          {{ $t('SendMoneyVerifyIdentityFailed.ButtonGetHelp').value }}
        </AppButton>
        <AppButton analytics-name="send-verify-identity-failed-change" @click="changeAmount">{{
          $t('SendMoneyVerifyIdentityFailed.ButtonChangeAmount').value
        }}</AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'

import { AppButton, AppCard, AppCardFooter, AppCardImageTitle } from '@oen.web.vue2/ui'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'SendMoneyVerifyIdentityFailed',
  components: {
    AppCardImageTitle,
    AppButton,
    AppCard,
    AppCardFooter,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const changeAmount = async () => {
      await router.goBackTo(
        {
          name: STEPS.SEND_MONEY_SUMMARY,
        },
        true
      )
    }
    return {
      changeAmount,
      $t,
    }
  },
}
</script>

<style scoped></style>
